.landing {
  /* background-color: #fff; */
  border-radius: 10px;
  /* padding: 20px 25px; */
}

h1,
h2,
h4,
h5,
p {
  margin: 0;
  /* color: #545554; */
}

.landing-selector {
  position: relative;
  display: inline-block;
  font-size: 14px;
}

.landing-selector select {
  width: 100%;
  min-height: 35px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  appearance: none;
  color: #fff;
  /* outline: none; */
}

.landing-selector select option {
  color: #222;
}

.landing-selector::after {
  content: "";
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #fff;
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 10px;
  border-radius: 2px;
}

.landing-link:hover {
  color: #f47b21;
}

.landing-bg {
  background-image: url("../../../assets/svg/Landing\ Page/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.load-treatment:hover {
  opacity: 0.5;
}

/* Clinician Profile */
.clinician-profile {
  max-width: 175px;
}

.clinician-name {
  color: #848786;
}

.clinician-title {
  color: #f36261;
  font-weight: 400;
  font-size: 1.2em;
}

.clock-in-button,
.clock-out-button {
  border: unset;
  color: #fff;
  font-weight: 500;
  font-size: 1.3em;
  padding: 0.5rem 1rem;
  min-width: 180px;
  transition: opacity 250ms ease;
}

.clock-in-button:hover,
.clock-out-button:hover {
  opacity: 0.8;
}

.clock-in-button {
  background-color: #f47b21;
}

.clock-out-button {
  background-color: #9c3e74;
}

/* Popup */
.popup-container {
  background: #fff;
  border-radius: 15px;
  min-width: 500px;
  top: 8rem;
  right: 1rem;
  z-index: 9999;
  /* opacity: 0.9; */
  border: 2px solid #f47b21;
  box-shadow: 7px 7px 5px #00000080;
  display: none;
}

.popup-header {
  font-size: 1.8em;
  letter-spacing: -1px;
}

.popup-note {
  line-height: 1.2;
}

.popup-select select {
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 700;
}

.popup-select:after {
  content: "";
  border-width: 8px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  display: inline-block;
  position: absolute;
  margin-right: 1rem;
  margin-top: 0.6rem;
  border-radius: 2px;
}

.popup-content-text,
.popup-content-author {
  font-size: 0.8em;
}

.popup-content {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  line-height: 1em;
}

.popup-footer {
  font-size: 0.7em;
}

.content-open-quote {
  left: -0.25rem;
}

.content-close-quote {
  margin-top: -1.5rem;
  /* right: 0; */
}

.popup-close {
  top: 0.5rem;
  right: 0.25rem;
  cursor: pointer;
}

.download-btn:hover {
  text-decoration: underline;
}

.overview-input2 {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border: 2px solid #f36917;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.overview-input2 .checkmark {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: #f36917;
  display: none;
}

.overview-input2 input {
  display: none;
}

.overview-input2 input:checked ~ .checkmark {
  display: inline-block;
}

.see-all:hover {
  text-decoration: underline;
}
