.avatar {
  width: 80px;
  height: 80px;
  margin-left: 4%;
  position: relative;
}

.avatar:hover {
  /* opacity: 25%; */
  cursor: pointer;
}

.avatarImg {
  border-radius: 50%;
}

.editAvatar:hover {
  text-decoration: underline;
}

/* .editAvatar {
  opacity: 0;
  position: absolute;
  font-size: 25px;
}

.editAvatar:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
} */
