/* This is being used by index.js which renders and displays in index.html*/

h1 {
  font-size: 1.3rem;
  color: #f57b22;
}

.patient-tab:hover {
  cursor: pointer;
}

/* Overview radio button */
.overview-input {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 3px solid #f36917;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.overview-input .checkmark {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: #f36917;
  display: none;
}

.overview-input input {
  display: none;
}

.overview-input input:checked ~ .checkmark {
  display: inline-block;
}

.careplan-checkbox {
  visibility: hidden !important;
}

.careplan-input {
  position: relative;
  cursor: pointer;
  font-size: 20px;
  horizontal-align: middle !important;
  vertical-align: middle !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.chkmrk {
  position: relative;
  height: 17px !important;
  width: 17px !important;
  background-color: white;
  outline: 3px solid #f36917;
  border-radius: 25px;
  padding: auto !important;
  margin: auto !important;
  justify-content: center !important;
  align-items: center !important;
  horizontal-align: middle !important;
  vertical-align: middle !important;
  justify-items: center !important;
  text-align: center !important;
  align-content: center !important;
}

.chkmrk:after {
  content: "";
  position: absolute;
  display: none;
}

.careplan-input input:checked ~ .chkmrk:after {
  display: flex;
}

.careplan-input .chkmrk:after {
  position: absolute;
  width: 14px !important;
  height: 14px !important;
  background-color: #f36917;
  border-radius: 20px;
  border: 0;
}

.input-orange-border {
  border: 4px solid #f57b22 !important;
}

*:focus {
  outline: none;
}

.care-plan-container {
  box-shadow: 0px 2px 4px 2px #d4d4d4;
}

.comment-expandable {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  height: 34px;
  max-height: 80px;
  width: 120px !important;
  position: relative;
  overflow: hidden;
}

.comment-container {
  position: relative;
}

.comment-container:focus-within {
  width: 500px;
  z-index: 100;
}

.comment-expandable:focus {
  width: 250px !important;
  height: 100px !important;
  position: relative;
  overflow: auto;
}

.announcement-card {
  border-radius: 3px !important;
}

.announcement-icon {
  color: white !important;
}

.select-mobility select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.select .careplan-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.handoverslist,
.notificationsList {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

table {
  border: 2px solid #fe6e18;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
}

th {
  border-bottom: 1px solid gray;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 8px;
  color: #545655;
}

td {
  text-align: center;
  color: #545655;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d8d8d8 !important;
}

.row-link:hover {
  cursor: pointer;
}

.row-link:hover * {
  color: #fe6e18;
}

.select:after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 0px 10px 10px 0px;
  background: #fe6e18;
  color: #fff;
  padding: 10px 11px;
  pointer-events: none;
}

.select {
  position: relative;
  width: 300px;
}

.switch {
  position: relative;
  width: 42px;
  height: 25px;
}

.switch input {
  opacity: 0;
  height: 0;
  width: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  border-radius: 20px;
  background-color: #dfdfdf;
}

.slider::before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 20px;
}

input:checked + .slider {
  background-color: #fe6e18;
}

input:checked + .slider::before {
  transform: translateX(17px);
}

/* main content div */
.hapi-main-content {
  background-color: #fffaf2;
}

.hapi-header {
  position: relative;
  z-index: 10;
  min-height: 110px;
}

.hapi-header-items > div > img {
  padding-left: 30%;
}

/* minimum height of hapi-main-body hapi-sidebar hapi-main-content */
.hapi-main-body,
.hapi-main-content {
  min-height: 100vh;
}

/* All form groups */
.form-group {
  margin-left: 10px;
}

.form-group label {
  color: #595a5b;
  font-weight: bold !important;
}

/* All cards!! */
.card {
  border-radius: 15px !important;
}

.card-body {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

/* Patient Bar */
.hapi-patient-bar {
  border-radius: 15px !important;
  background-color: #f57b22 !important;
  transition: 0.14s;
}

.hapi-patient-bar-single {
  border-radius: 15px !important;
  background-color: #f57b22 !important;
}

.hapi-tab-link:hover {
  background-color: #f57b22 !important;
}

.hapi-tab-link.active {
  background-color: #f57b22 !important;
  box-shadow: 5px 10px 10px rgba(199, 197, 197, 0.801);
}

.hapi-sidebar {
  min-width: 30px !important;
}

img.svg-sidebar {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  margin-top: 1px;
}

/* Footer Stuff */
.footerlinks {
  color: white;
  text-decoration: none;
}

.footerlinks:hover,
.sidebar-link:hover {
  /* color: #bd620d; */
  color: #f57b22;
  text-decoration: none;
}

.sidebar-sub-link:hover {
  /* color: #bd620d; */
  color: #f57b22;
  text-decoration: underline;
}

.sidebar-link {
  color: #545655;
  font-weight: bolder;
  transition: 0.14s;
}

.term-title {
  margin: 10px;
}

.term-subtitle > p {
  padding: 5px;
}

.nav-item {
  width: 100%;
  cursor: pointer;
}

.nav-link:hover {
  cursor: pointer;
}

.nav-item.active {
  background-color: #fef4e2;
}

.nav-item:hover {
  background-color: #fffaf2;
  cursor: pointer;
}

.nav-spacing {
  width: 10% !important;
}

.orange-1 {
  color: #f36917;
}

.orange-bg-2 {
  background-color: #973d08;
}

.grey-bg-1 {
  background-color: #a9a9a9;
}

.orange-bg-1 {
  background-color: #f36917;
}

.orange-encircle-1 {
  border-radius: 100px !important;
  border: 2px solid #f36917 !important;
  background-color: white;
  padding: 5px;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
}

.text-align-left {
  text-align: left;
}

.padding-10 {
  padding: 10px;
}
.bg-yellow {
  background-color: #fffaf2;
}

.orange-border-1 {
  border-radius: 10px !important;
  border: 2px solid #f36917 !important;
  background-color: white;
}

.orange-border-1:hover {
  border: 2px solid #f36917 !important;
  background-color: #f36917;
  color: #fff;
  text-decoration: none;
}

.orange-border-2 {
  border-radius: 10px !important;
  border: 2px solid #f47b21 !important;
  background-color: white;
}

.border-10px {
  border-radius: 10px;
}

.border-15px {
  border-radius: 15px;
}

.border-25px {
  border-radius: 25px;
}

.grey-text-1 {
  color: #8a8b8b;
}

.grey-text-2 {
  color: #a6a8a7;
}

.green-text-1 {
  color: #8bc63e;
}

.purple-text-1 {
  color: #7b3185;
}

/* hapi file icon to be clicked for downloading pdf files */
.hapi-file-download {
  font-size: 4rem;
}

.hapi-patient-bar:hover {
  background-color: #bd620d !important;
}

/*For Bootstrap buttons primary and success*/
.btn-primary,
.btn-success {
  background-color: #f36917 !important;
  border-color: #f36917 !important;
}

.btn-primary:active,
.btn-success:active {
  background-color: #f36917 !important;
  border-color: #f36917 !important;
}

.btn-primary:hover,
.btn-success:hover {
  background-color: #bd620d !important;
  border-color: #f36917 !important;
}

.btn-primary:focus,
.btn-success:focus {
  background-color: #f36917 !important;
  border-color: #f36917 !important;
}

/*For Bootstrap buttons warning and danger*/
.btn-warning,
.btn-danger {
  background-color: #595a5b !important;
  border-color: #595a5b !important;
}

.btn-warning:active,
.btn-danger:active {
  background-color: #595a5b !important;
  border-color: #595a5b !important;
}

.btn-warning:hover,
.btn-danger:hover {
  background-color: #595a5b !important;
  border-color: #595a5b !important;
}

.btn-warning:focus,
.btn-danger:focus {
  background-color: #595a5b !important;
  border-color: #595a5b !important;
}

.fa-redo {
  font-size: 11px;
}

/* Default from React.js */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Okta Widget and others */
.auth-container {
  margin-top: 3rem !important;
}

#okta-signin-submit {
  background-color: #f36917 !important;
  background: #f36917 !important;
  border-color: #f36917 !important;
}

.w-90 {
  width: 90%;
}

.notification {
  border-radius: 10px !important;
  background-color: #833f84 !important;
  transition: 0.14s;
}

.selected {
  transform: scale(1.03);
  background-color: #f36917 !important;
}

.notification > h6 {
  font-size: 0.7rem;
}

.notification > p {
  font-size: 0.6375rem;
}

.notification:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #f36917 !important;
}

.hero {
  position: relative;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("./assets//images/medicalgirl.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.hero-text {
  position: relative;
  color: #f36917;
  font-size: 2rem;
  line-height: 2.675rem;
  text-align: center;
}

.right-label {
  position: absolute;
  top: -23px;
  color: #f57b22;
  font-size: 14px;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
}

.assessments-bg {
  background-image: url("./assets/svg/Assessments/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 30px;
}

.progress-bg {
  background-image: url("./assets/svg/Progress\ Notes/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 30px;
}

.careplans-bg,
.overview-bg {
  background-image: url("./assets/svg/Care\ Plans/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 30px;
}

.schedule-bg {
  background-image: url("./assets/svg//Schedule/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 30px;
}
.flex-align-center {
  align-items: center;
}

.addNewWard {
  cursor: pointer;
  color: #195fff;
}

.addNewWard:hover {
  text-decoration: underline;
}
.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li:hover {
  background-color: #a9a9a9;
}

.tabLinksDropdown {
  padding: 0;
}

.tabLinksDropdown a {
  background-color: #f57b22;
  border-radius: 10px;
  color: #fff !important;
}

.tabLinksDropdown a:hover {
  background-color: #833f84;
  color: #fff !important;
}

.tabLinksDropdownItem {
  display: block;
  /* width: 100%; */
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

/* select facility on patients list */
.select__menu.css-1nmdiq5-menu {
  z-index: 10;
}

/* for week view overlapping sched */
.fc-event-title,
.fc-event-time {
  text-align: left;
  width: 75%;
}

.fc .fc-timegrid-slot {
  padding-top: 15px;
  padding-bottom: 15px;
}

.notifArray:hover,
.dashboardNotif:hover {
  background-color: #e8e8e8;
  text-decoration: none;
}

/* Hide scrollbar */
.notificationPopup::-webkit-scrollbar {
  display: none;
}

.notificationPopup {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modernDatePickerStyle {
  border-radius: 15px;
  border: 2px solid #fe6e18;
  padding: 3px;
}

.exportOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .margin-md {
    margin-bottom: 150px;
  }
}
