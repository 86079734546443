.support-ticket {
  /* background-color: #fff; */
  border-radius: 10px;
  /* padding: 20px 25px; */
}

.support-success-bg {
  background-image: url("../../../assets/svg/Support/Success/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.support-create-bg {
  background-image: url("../../../assets/svg/Support/CreateTicket/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.support-overview-bg {
  background-image: url("../../../assets/svg/Support/Overview/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.support-ticket h1 {
  background-color: #f36917;
  border-radius: 10px;
}

.support-ticket label {
  margin-left: 10px;
}

select {
  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.classic {
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%),
    linear-gradient(to right, #f36917, #f36917);
  background-position: calc(100% - 15px) calc(0.85em),
    calc(100% - 5px) calc(0.85em), 100% 0;
  background-size: 10px 10px, 10px 10px, 2em 2.5em;
  background-repeat: no-repeat;
}
/* #f57b22 */
select.classic:focus {
  background-image: linear-gradient(45deg, #fff 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #fff 50%),
    linear-gradient(to right, #f57b22, #f57b22);
  background-position: calc(100% - 5px) 0.85em, calc(100% - 15px) 0.85em, 100% 0;
  background-size: 10px 10px, 10px 10px, 2em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

.priority-red {
  height: 10px;
  width: 10px;
  background-color: red;
}

::-webkit-file-upload-button {
  background: #f36917;
  color: white;
  padding: 2px 15px;
  font-style: normal;
  border-color: #f36917;
  border-radius: 25px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

.ticket {
  color: grey;
  font-size: 20px;
  font-weight: 650;
}
